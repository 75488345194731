import React from 'react';
import { Link } from 'gatsby';

import finger1 from 'assets/images/lapa1.png';
import finger2 from 'assets/images/lapa2.png';

import { TermsList } from 'components/Global.styles';
import { PagesWrapper, Page, FingerWrapper, Title, ContentWrapper } from './Pages.styles';

const Pages = ({ prev, next }) => {
  const { title: prevTitle, uri: prevUri, portfolioTypes: { nodes: prevTerms } = {} } = { ...prev };
  const { title: nextTitle, uri: nextUri, portfolioTypes: { nodes: nextTerms } = {} } = { ...next };

  return (
    <PagesWrapper>
      {prev && (
        <Page side="left" as={Link} to={prevUri}>
          <FingerWrapper>
            <img src={finger1} alt="" />
          </FingerWrapper>
          <ContentWrapper>
            <Title as="span">{prevTitle}</Title>
            <TermsList hasSeparator>
              {prevTerms && prevTerms.map((term) => <li key={term.databaseId}>{term.name}</li>)}
            </TermsList>
          </ContentWrapper>
        </Page>
      )}

      {next && (
        <Page side="right" as={Link} to={nextUri}>
          <ContentWrapper>
            <Title as="span">{nextTitle}</Title>
            <TermsList hasSeparator>
              {nextTerms && nextTerms.map((term) => <li key={term.databaseId}>{term.name}</li>)}
            </TermsList>
          </ContentWrapper>
          <FingerWrapper>
            <img src={finger2} alt="" />
          </FingerWrapper>
        </Page>
      )}
    </PagesWrapper>
  );
};

export default Pages;
