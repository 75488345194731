import styled from 'styled-components';
import Img from 'gatsby-image';
import { Row } from 'react-bootstrap';

export const ImgWrapper = styled.div`
  flex: auto;
  max-width: ${({ imageWidth }) => (imageWidth ? `${imageWidth}%` : `100%`)};
  ${({ theme }) => theme.mq.tabletMiddle} {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }

  [data-rmiz-wrap] {
    width: 100%;
  }
`;

export const StyledImg = styled(Img)`
  width: 100%;
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : `100%`)};
  max-width: 100%;
  transition: border-radius 250ms ease;
  border-radius: ${({ zoomed }) => (zoomed ? `0px` : `25px`)};
  ${({ theme }) => theme.mq.mobile} {
    border-radius: ${({ zoomed }) => (zoomed ? `0px` : `10px`)};
  }
`;

export const StyledRow = styled(Row)`
  justify-content: center;
  gap: 30px 145px;
  ${({ theme }) => theme.mq.tabletMiddle} {
    gap: 30px 0;
  }
  ${({ theme }) => theme.mq.mobile} {
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  &:empty {
    margin: 0;
  }
`;
