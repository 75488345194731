import styled from 'styled-components';
import { StyledH1, TermsList } from 'components/Global.styles';

export const FingerWrapper = styled.div`
  svg,
  img {
    width: 120px;
    height: auto;
  }
`;

export const Title = styled(StyledH1)`
  font-size: 3rem;
  text-align: left;
`;

export const ContentWrapper = styled.div`
  display: block;
  ${({ theme }) => theme.mq.mobile} {
    display: none;
  }
`;

export const Page = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  display: flex;
  align-items: center;
  justify-content: ${({ side }) => (side === 'left' ? `flex-start` : `flex-end`)};
  text-decoration: none;
  ${({ theme }) => theme.mq.mobile} {
    justify-content: center;
  }

  &:only-child {
    margin-left: ${({ side }) => (side === 'right' ? `auto` : `0`)};
  }

  &:hover {
    text-decoration: none;

    ${FingerWrapper} {
      transform: scaleX(${({ side }) => (side === 'left' ? `-1` : `1`)}) translateX(5px);
    }
  }

  ${FingerWrapper} {
    transform: scaleX(${({ side }) => (side === 'left' ? `-1` : `1`)});
    transition: ${({ theme }) => theme.transition.default};
  }

  ${Title} {
    text-align: ${({ side }) => side};
    display: block;
    margin: 0;
  }

  & > div {
    margin: 0 15px;
  }

  ${TermsList} {
    margin-top: 6px;
    justify-content: ${({ side }) => (side === 'left' ? `flex-start` : `flex-end`)};
  }
`;

export const PagesWrapper = styled.div`
  margin-top: 64px;
  display: flex;
`;
