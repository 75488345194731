import React from 'react';
import styled from 'styled-components';

const StyledVideoWrapper = styled.div`
  width: 100%;
`;

const StyledVideo = styled.video`
  width: 100%;
  border-radius: 25px;
  ${({ theme }) => theme.mq.mobile} {
    border-radius: 10px;
  }
`;

const Movie = ({ movie }) => {
  const movieUrl = movie?.localFile?.publicURL ?? movie.mediaItemUrl;
  if (!movieUrl) {
    return null;
  }

  return (
    <StyledVideoWrapper>
      <StyledVideo muted autoPlay loop playsInline data-fsz={movie.fileSize}>
        <source src={movieUrl} type={movie.mime_type} />
      </StyledVideo>
    </StyledVideoWrapper>
  );
};

export default Movie;
