import styled from 'styled-components';
import { transparentize } from 'polished';
import { StyledH2 } from '../Global.styles';
import { MenuButton } from '../Header/Header.styles';

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 900;
  background-color: ${({ theme }) => transparentize(0.5, theme.colors.defaultBackground)};
  backdrop-filter: blur(15px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalBody = styled.div`
  position: relative;
  width: calc(100% - 30px);
  max-width: 575px;
  background-color: ${({ theme }) => theme.colors.defaultBackground};
  border-radius: 20px;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.07);
  padding: 60px 35px;
`;

export const ModalTitle = styled(StyledH2)`
  text-align: center;
  margin-bottom: 16px;
`;

export const ModalContent = styled.p`
  margin-bottom: 16px;
  font-size: 1.6rem;
  line-height: 1.375;
  text-align: center;
  color: ${({ theme }) => theme.colors.text};
`;

export const Fieldset = styled.fieldset`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  display: block;
  padding: 0 20px;
`;

export const Input = styled.input`
  border: 1px solid ${({ theme }) => theme.colors.text};
  border-radius: 14px;
  padding: 20px;
  background: none;
  outline: none;

  &:focus {
    border-color: ${({ theme }) => theme.colors.heading};
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.heading};
  }
`;

export const HelperText = styled.div`
  color: ${({ theme }) => theme.colors.error};
  font-style: italic;
  padding: 0 20px;
`;

export const Button = styled(MenuButton)`
  box-shadow: none;
  font-size: 2rem;
  margin-top: 12px;
  margin-left: auto;
  padding: 12px 45px;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.heading};
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  position: absolute;
  right: 15px;
  top: 15px;
`;
