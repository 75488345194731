import React, { useCallback, useState } from 'react';
import { StyledImg, ImgWrapper, StyledRow } from './ResponsiveImages.styles';
import { ZoomWrapper } from '../ZoomWrapper';

/**
 * Parallax
 * https://www.react-spring.io/docs/props/parallax
 *
 * Zooming image
 * https://github.com/rpearce/image-zoom
 */

const ResponsiveImages = ({ images }) => {
  if (!images) {
    return null;
  }

  return (
    <StyledRow>
      {images.map((imageData) => {
        if (!imageData || !imageData?.image) {
          return null;
        }

        const Wrapper = imageData.zoomable ? ZoomWrapper : React.Fragment;

        const [isZoomed, setIsZoomed] = useState(false);

        const handleZoomChange = useCallback((shouldZoom) => {
          setIsZoomed(shouldZoom);
        }, []);

        return (
          <ImgWrapper key={imageData.image.databaseId} imageWidth={imageData.width}>
            <div style={{ position: 'relative' }}>
              <Wrapper isZoomed={isZoomed} onZoomChange={handleZoomChange}>
                <StyledImg
                  fluid={imageData.image.localFile.childImageSharp.fluid}
                  zoomed={isZoomed}
                />
              </Wrapper>
            </div>
          </ImgWrapper>
        );
      })}
    </StyledRow>
  );
};

export default ResponsiveImages;
