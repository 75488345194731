import React, { useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import { navigate } from 'gatsby';
import {
  Button,
  Fieldset,
  HelperText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalContent,
  ModalTitle,
  CloseButton,
} from './PasswordModal.styles';
import CloseIcon from '../../assets/icons/close-modal.svg';

export const PasswordModal = ({
  title = '',
  content = '',
  password = '',
  showPage,
  databaseId,
}) => {
  if (!password) return null;

  const [hasError, setError] = useState(false);
  const inputRef = useRef(null);

  const handleModalClose = () => {
    navigate('/');
  };

  const handlePasswordSubmit = (event) => {
    event.preventDefault();
    setError(false);
    if (inputRef.current && inputRef.current?.value && inputRef.current.value === password) {
      let localStorageUnlocked = [];
      try {
        const rawUnlocked = localStorage.getItem('unlocked') || '';
        const unlocked = rawUnlocked ? JSON.parse(rawUnlocked) : [];
        if (Array.isArray(unlocked)) {
          localStorageUnlocked = unlocked;
        }
      } catch (err) {
        console.log(err);
      }
      const nextWeek = new Date();
      nextWeek.setDate(nextWeek.getDate() + 7);
      localStorage.setItem(
        'unlocked',
        JSON.stringify([...localStorageUnlocked, { id: databaseId, expires: nextWeek.getTime() }])
      );
      showPage();
    } else {
      setError(true);
    }
  };

  if (typeof window === 'undefined') {
    return null;
  }

  return createPortal(
    <Modal>
      <ModalBody>
        <CloseButton type="button" onClick={handleModalClose}>
          <CloseIcon />
        </CloseButton>
        {title && <ModalTitle>{title}</ModalTitle>}
        {content && <ModalContent dangerouslySetInnerHTML={{ __html: content }} />}
        <form onSubmit={handlePasswordSubmit}>
          <Fieldset>
            <Label htmlFor="password">Password</Label>
            <Input ref={inputRef} id="password" type="password" placeholder="Password" />
            {hasError && <HelperText>Wrong password</HelperText>}
          </Fieldset>
          <Button type="submit">Enter</Button>
        </form>
      </ModalBody>
    </Modal>,
    document.body
  );
};
