import React, { useContext, useEffect, useState } from 'react';
import SEO from 'layout/SEO';
import { graphql } from 'gatsby';

import { Container, Row, Col } from 'react-bootstrap';

import Columns from 'components/modules/Columns';
import ResponsiveImages from 'components/modules/ResponsiveImages';
import Movie from 'components/modules/Movie';
import Pages from 'components/Pages/Pages';

import { PageWrapper, StyledContent, StyledH1 } from 'components/Global.styles';
import {
  TopBackground,
  StyledTerms,
  StyledRow,
  Placeholder,
  StyledCol,
} from './SinglePortfolioItem.styles';
import { PasswordModal } from '../../components/PasswordModal/PasswordModal';
import { TwoColumns } from '../../components/modules/TwoColumns';
import { ZoomContext } from '../../layout/ZoomContext';

const SinglePortfolioItem = ({ location, data }) => {
  const { wpSinglePortfolioItem: portfolioData } = data;
  const { acfSinglePortfolioItem: acf, title, databaseId } = portfolioData;
  const terms = portfolioData?.portfolioTypes?.nodes;
  const acfModules = portfolioData?.acfModules?.modules;
  const { lockedPopupTitle = '', lockedPopupContent = '', password = '', locked = false } = acf;

  const [, setEnableZoom] = useContext(ZoomContext);
  const [show, setShow] = useState(!locked);
  useEffect(() => {
    setEnableZoom(show);
  }, [show, setEnableZoom]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      try {
        const rawUnlocked = localStorage.getItem('unlocked') || '';
        const unlocked = rawUnlocked ? JSON.parse(rawUnlocked) : [];

        if (Array.isArray(unlocked)) {
          const now = new Date().getTime();
          const shallPass = unlocked.find(({ expires }) => {
            return now < expires;
          });
          if (shallPass) {
            setShow(true);
          } else {
            localStorage.setItem(
              'unlocked',
              JSON.stringify(
                unlocked.filter(({ id }) => {
                  return id !== databaseId;
                }) || []
              )
            );
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
  }, []);

  if (!show) {
    return (
      <>
        <SEO title={title} pathname={location.pathname} />
        <PageWrapper>
          <Placeholder />
          <PasswordModal
            title={lockedPopupTitle || title}
            content={lockedPopupContent || ''}
            password={password}
            showPage={() => setShow(true)}
            databaseId={databaseId}
          />
        </PageWrapper>
      </>
    );
  }

  return (
    <>
      <SEO title={title} pathname={location.pathname} />
      <PageWrapper>
        <Container>
          {acf.topBackground && (
            <Row>
              <Col xs={12}>
                <TopBackground fluid={acf.topBackground.localFile.childImageSharp.fluid} />
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={{ span: 12 }} md={{ span: 7 }}>
              <StyledH1 marginSmaller fontSmaller textAlign="left">
                {title}
              </StyledH1>
              <StyledContent
                fontSmaller
                dangerouslySetInnerHTML={{
                  __html: acf.description,
                }}
              />
            </Col>
            <Col
              xs={{
                span: 12,
                order: 1,
              }}
              md={{
                span: 4,
                offset: 1,
                order: 2,
              }}
            >
              <StyledTerms>
                {terms.map((term) => (
                  <li key={term.databaseId}>{term.name}</li>
                ))}
              </StyledTerms>
            </Col>
          </Row>
          <StyledRow>
            <StyledCol>
              {acfModules &&
                acfModules.map(({ fieldGroupName, ...module }, idx) => {
                  const moduleName = fieldGroupName.split('_').pop();
                  const moduleId = Object.values(module)?.[0]?.id ?? idx;

                  switch (moduleName) {
                    case 'Columns':
                      return <Columns key={moduleId} {...module} />;
                    case 'ResponsiveImages':
                      return <ResponsiveImages key={moduleId} {...module} />;
                    case 'Movie':
                      return <Movie key={moduleId} {...module} />;
                    case 'TwoColumns':
                      return <TwoColumns key={moduleId} {...module} />;
                    default:
                      return null;
                  }
                })}
            </StyledCol>
          </StyledRow>

          <Row>
            <Col>
              <Pages base="/portfolio" prev={acf.prevPage} next={acf.nextPage} />
            </Col>
          </Row>
        </Container>
      </PageWrapper>
    </>
  );
};

export default SinglePortfolioItem;

export const query = graphql`
  query singlePortfolioItemQuery($slug: String!) {
    wpSinglePortfolioItem(slug: { eq: $slug }) {
      acfSinglePortfolioItem {
        description
        topBackground {
          mediaDetails {
            width
          }
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        locked
        password
        lockedPopupTitle
        lockedPopupContent
        nextPage {
          ... on WpSinglePortfolioItem {
            databaseId
            slug
            title
            uri
            portfolioTypes {
              nodes {
                databaseId
                name
              }
            }
          }
        }
        prevPage {
          ... on WpSinglePortfolioItem {
            databaseId
            slug
            title
            uri
            portfolioTypes {
              nodes {
                databaseId
                name
              }
            }
          }
        }
        listTitle
        listThumbnail {
          mediaDetails {
            width
          }
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
      portfolioTypes {
        nodes {
          databaseId
          name
        }
      }
      slug
      databaseId
      title
      acfModules {
        modules {
          ... on WpSinglePortfolioItem_Acfmodules_Modules_Columns {
            fieldGroupName
            content
          }
          ... on WpSinglePortfolioItem_Acfmodules_Modules_ResponsiveImages {
            fieldGroupName
            images {
              zoomable
              paralax
              width
              image {
                databaseId
                mediaDetails {
                  width
                  height
                }
                localFile {
                  url
                  childImageSharp {
                    fluid(maxWidth: 1920, quality: 100) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
          }
          ... on WpSinglePortfolioItem_Acfmodules_Modules_Movie {
            fieldGroupName
            movie {
              mimeType
              fileSize
              mediaItemUrl
              localFile {
                url
                publicURL
              }
            }
          }
          ... on WpSinglePortfolioItem_Acfmodules_Modules_TwoColumns {
            fieldGroupName
            zoomable
            leftColumnType
            leftColumnText
            leftColumnImage {
              databaseId
              mediaDetails {
                width
                height
              }
              localFile {
                url
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 80) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            rightColumnType
            rightColumnText
            rightColumnImage {
              databaseId
              mediaDetails {
                width
                height
              }
              localFile {
                url
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 80) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
