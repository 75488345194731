import React, { useCallback, useState } from 'react';
import 'react-medium-image-zoom/dist/styles.css';
import { Content, Image, ImageWrapper, TwoColumnsWrapper } from './TwoColumns.styles';
import { ZoomWrapper } from '../ZoomWrapper';

const Column = ({ type = 'text', image = null, text = '', image_zoomable = false }) => {
  if (type === 'image' && image) {
    const [isZoomed, setIsZoomed] = useState(false);

    const handleZoomChange = useCallback((shouldZoom) => {
      setIsZoomed(shouldZoom);
    }, []);

    const Wrapper = image_zoomable ? ZoomWrapper : React.Fragment;
    return (
      <ImageWrapper>
        <Wrapper isZoomed={isZoomed} onZoomChange={handleZoomChange}>
          <Image
            fluid={image.localFile.childImageSharp.fluid}
            maxWidth={image.mediaDetails.width}
            imgStyle={{ objectFit: 'contain' }}
            zoomed={isZoomed}
          />
        </Wrapper>
      </ImageWrapper>
    );
  }

  if (type === 'text' && text) {
    return (
      <Content
        fontSmaller
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
    );
  }

  return null;
};

export const TwoColumns = ({
  leftColumnType = 'text',
  leftColumnImage = null,
  leftColumnText = '',
  rightColumnType = 'text',
  rightColumnImage = null,
  rightColumnText = '',
  zoomable = false,
}) => {
  return (
    <TwoColumnsWrapper>
      <Column
        type={leftColumnType}
        image={leftColumnImage}
        text={leftColumnText}
        image_zoomable={zoomable}
      />
      <Column
        type={rightColumnType}
        image={rightColumnImage}
        text={rightColumnText}
        image_zoomable={zoomable}
      />
    </TwoColumnsWrapper>
  );
};
