import React from 'react';
import { Controlled as ControlledZoom } from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

export const ZoomWrapper = ({ children, isZoomed, onZoomChange }) => {
  return (
    <ControlledZoom
      isZoomed={isZoomed}
      onZoomChange={onZoomChange}
      overlayBgColorEnd="rgba(249, 243, 221, 0.8)"
      zoomMargin={60}
      classDialog="zoom-dialog"
    >
      {children}
    </ControlledZoom>
  );
};
