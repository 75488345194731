import styled from 'styled-components';
import Img from 'gatsby-image';
import { StyledContent } from '../Global.styles';

export const TwoColumnsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 40px 50px;
  ${({ theme }) => theme.mq.tabletMiddle} {
    flex-direction: column;
  }
`;

export const Content = styled(StyledContent)`
  margin: 0;
  flex: 1;
  align-self: flex-start;
`;

export const ImageWrapper = styled.div`
  flex: 1;
  max-width: 100%;
  position: relative;
  display: flex;
  ${({ theme }) => theme.mq.tabletMiddle} {
    width: 100%;
  }

  [data-rmiz-wrap] {
    width: 100%;
  }
`;

export const Image = styled(Img)`
  flex: 1;
  width: 100%;
  max-width: 100%;
  box-shadow: ${({ theme }) => theme.box_shadow(0, 0, 10, 0.25)};
  transition: border-radius 250ms ease;
  border-radius: ${({ zoomed }) => (zoomed ? `0px` : `20px`)};
  ${({ theme }) => theme.mq.mobile} {
    border-radius: ${({ zoomed }) => (zoomed ? `0px` : `10px`)};
  }
`;
