import React from 'react';
import { StyledContent } from 'components/Global.styles';

const Columns = ({ content }) => (
  <StyledContent
    fontSmaller
    dangerouslySetInnerHTML={{
      __html: content,
    }}
  />
);

export default Columns;
